<template>
  <b-card
      class="card-transaction"
      no-body
  >
    <b-card-header class="pb-50">
      <b-card-title>{{ capitalize($tc('quote', 2)) }}</b-card-title>
    </b-card-header>
    <b-card-body>
      <card-document
          v-for="quote in documentQuotes"
          :document="quote"
          :isDeletable="false"
          @download="download(quote)"
          @click="download(quote)"
      />
    </b-card-body>

    <b-card-header
        v-if="offer.signedDocument"
        class="pb-50">
      <b-card-title>{{ capitalize($t('signedDocument')) }}</b-card-title>
    </b-card-header>
    <b-card-body
        v-if="offer.signedDocument"
    >
      <card-document
          :document="offer.signedDocument"
          :isDeletable="false"
          @download="download(offer.signedDocument)"
          @click="download(offer.signedDocument)"
      />
    </b-card-body>

    <b-card-header
        class="pb-50">
      <b-card-title>{{ capitalize($t('linkedInvoices')) }}</b-card-title>
    </b-card-header>
    <b-card-body>
<!--      <card-document-->
<!--          v-for="product in productInvoices"-->
<!--          :document="{extension: 'pdf', name: product.outgoingInvoice.billNumber==null?capitalize($t('draft')):product.outgoingInvoice.billNumber}"-->
<!--          :isDeletable="false"-->
<!--          :isLinkable="true"-->
<!--          @click="downloadOutgoingInvoice(product.outgoingInvoice)"-->
<!--          @toRoute="$router.push({name: 'OutgoingInvoice view', params: {'outgoingInvoiceId': product.outgoingInvoice.id}})"-->
<!--      />-->
      <card-document
          v-for="outgoingInvoice in offer._linkedOutgoingInvoices"
          :document="{extension: 'pdf', name: outgoingInvoice.billNumber==null?capitalize($t('draft')):outgoingInvoice.billNumber}"
          :isDeletable="false"
          :isLinkable="true"
          @click="downloadOutgoingInvoice(outgoingInvoice)"
          @download="downloadOutgoingInvoice(outgoingInvoice)"
          @toRoute="$router.push({name: 'OutgoingInvoice view', params: {'outgoingInvoiceId': outgoingInvoice.id}})"
      />
    </b-card-body>
  </b-card>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { useOutgoingInvoices } from '../../outgoingInvoices/useOutgoingInvoices'
import { capitalize } from '@/utils/filter'

// import CardDocument from '@/components/form/order/components/_card_document'
import CardDocument from '@/components/card/Document'
import i18n from '@/libs/i18n'

export default {
  components: {
    CardDocument
  },
  props: {
    offer: {
      type: Object,
      default: () => {}
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const quoteTitle = computed(() => {
      let displayQuoteNumber = capitalize(i18n.t('quote')) + ' ' + props.offer.billNumber + '-'
      if (props.offer.version < 10) {
        displayQuoteNumber += ('00' + props.offer.version.toString())
      } else {
        if (props.offer.version < 100) {
          displayQuoteNumber += ('0' + props.offer.version.toString())
        } else {
          displayQuoteNumber += props.offer.version.toString()
        }
      }
      return displayQuoteNumber
    })

    const productInvoices = computed(() => {
      return props.offer.products.filter(p => (p.outgoingInvoice != null))
    })

    const documentQuotes = computed(() => {
      return props.offer.quotes.reverse()
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { downloadOutgoingInvoice } = useOutgoingInvoices()

    const download = (documentToDownload) => {
      let link = document.createElement('a')
      link.setAttribute('target', '_blank')
      link.href = documentToDownload.url
      link.click()
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data

      // Computed
      quoteTitle,
      productInvoices,
      documentQuotes,

      // Methods
      downloadOutgoingInvoice,
      download,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>